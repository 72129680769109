import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Us presentem un simulacre de físiques molt especial!</strong>
                </p>

                <p>
                    I és que enguany hem preparat un simulacre on podràs comprovar si estàs en els
                    barems que demanen a les bases de l'oposició de Mossos d'Esquadra amb pressa de
                    marques.
                </p>

                <p>
                    I, per altra banda, trobareu una zona d'avituallament amb{" "}
                    <strong>música en directe</strong>, <strong>begudes energètiques</strong> i
                    podreu conèixer a <strong>docents i alumnes de iOpos Acadèmia</strong>.
                </p>

                <p>
                    En definitiva, serà una bona excusa per posar-vos a prova i socialitzar amb
                    altres companys.
                </p>

                <p>
                    <strong>
                        El simulacre es realitzarà al CrossFit Lynx de Sant Cugat del Vallès.
                    </strong>
                </p>

                <p>
                    <strong>Hi haurà 2 torns:</strong>
                </p>

                <ul>
                    <li>
                        <strong>Primer torn:</strong> 16.00 a 18.00 h
                    </li>
                    <li>
                        <strong>Segon torn:</strong> 18.00 a 20.00 h
                    </li>
                </ul>

                <p>
                    <strong>Hi haurà un màxim de participants!</strong>
                </p>

                <p>
                    Els torns s'ompliran per ordre d'inscripció, un cop s'ompli el primer torn,
                    obrirem el segon torn.
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="No deixis escapar l'oportunitat de preparar-te amb el nostre Simulacre de Físiques 2025 al CrossFit Lynx! Aprofita els torns disponibles i supera el repte per només 17.99€. Places limitades!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
